<template>
    <div class="legal">
        <section class="text-center bg-image-6">
            <img src="@/assets/images/banner-ctp.png" alt="banner" />
        </section>

        <!-- Presentation-->
        <section style="margin-top: -25px"
            class="section novi-bg novi-bg-img section-xl bg-default text-center background-privacidad"
            id="section-see-features">
            <div class="container">
                <div class="row justify-content-lg-center">
                    <div class="col-lg-10 col-xl-8">
                        <h2>
                            <b>{{ $t('lang.cookie.title') }}</b>
                        </h2>

                        <div style="text-align: left; margin-top: 50px">
                            <h5 style="font-weight: 600">{{ $t('lang.cookie.subtitle_1') }}</h5>
                            <p v-html="$t('lang.cookie.description_1')"></p>
                            <p v-html="$t('lang.cookie.description_2')"></p>
                            <p v-html="$t('lang.cookie.description_3')"></p>
                        </div>

                        <div style="text-align: left; margin-top: 50px">
                            <h5 style="font-weight: 600">{{ $t('lang.cookie.subtitle_2') }}</h5>
                            <p v-html="$t('lang.cookie.description_4')"></p>
                            <p v-html="$t('lang.cookie.description_5')"></p>
                            <p v-html="$t('lang.cookie.description_6')"></p>
                            <p v-html="$t('lang.cookie.description_7')"></p>
                            <p v-html="$t('lang.cookie.description_8')"></p>
                        </div>

                        <div style="text-align: left; margin-top: 50px">
                            <h5 style="font-weight: 600">{{ $t('lang.cookie.subtitle_3') }}</h5>
                            <p v-html="$t('lang.cookie.description_4')"></p>
                            <p v-html="$t('lang.cookie.description_5')"></p>
                            <p v-html="$t('lang.cookie.description_6')"></p>
                            <p v-html="$t('lang.cookie.description_7')"></p>
                            <p v-html="$t('lang.cookie.description_8')"></p>
                            <table class="table mt-4">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Propietario</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Plazo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>_gid</td>
                                        <td>google.com</td>
                                        <td>contabilizar las visitas</td>
                                        <td>1 día</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>_ga</td>
                                        <td>google.com</td>
                                        <td>identificación de usuarios para analiticas</td>
                                        <td>2 años</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="text-align: left; margin-top: 50px">
                            <h5 style="font-weight: 600">{{ $t('lang.cookie.subtitle_4') }}</h5>
                            <p v-html="$t('lang.cookie.description_10')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
    name: 'Legal',
    data: () => ({})
});
</script>
